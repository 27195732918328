import 'slick-carousel/slick/slick';

function loadSlickSlideshow() {
    $('.slick-slideshow').slick({
        infinite: false,
        dots: true,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    });
}

window.loadSlickSlideshow = loadSlickSlideshow;
loadSlickSlideshow();